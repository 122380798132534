import * as React from "react";
import {UserSectionListProps} from "../models/Sections";
import {Link, useNavigate} from "react-router-dom";
import {returnFlatSectionArray} from "../Utils/SectionHelper";


export default function UserSectionList(props: UserSectionListProps) {
    const navigate = useNavigate()

    const handleMobileClickEvent = async (slug) => {

        await navigate(slug);
        props.setMobileSidebarOpen(false);
    }

    return (
        <>
            {
                props.sections.map((section, index) => {
                    const flattenedArray = returnFlatSectionArray(section);
                    return flattenedArray.map((item, index) => {
                        let itemClasses = '';
                        let isParent = false;
                        if (index > 0 && item.parent !== "0") {
                            if (item.clickable) {
                                itemClasses = 'pl-8 pt-2 pb-2 hover:bg-grey-300 rounded pl-2 w-full';
                            } else {
                                itemClasses = 'pl-8 pt-2 pb-2';
                            }
                        } else {
                            if (item.clickable) {
                                itemClasses = 'pl-0 pt-2 pb-2 rounded pl-2 w-full hover:bg-grey-300';
                            } else {
                                itemClasses = 'pl-0 pt-2 pb-2 rounded pl-2 w-full';
                            }
                        }
                        if (item.clickable) {
                            if (section.slug === props.slug) {
                                itemClasses += ' bg-grey-300 border-grey-400 border-1 hover:bg-grey-300'
                            }
                            if (!props.isMobile) {
                                return (
                                    <div className={itemClasses} key={index}>
                                        <Link to={item.slug}>
                                            {item.section_name}
                                        </Link>
                                    </div>
                                )
                            } else {
                                return (
                                    <div className={itemClasses} key={index}>
                                        <a onClick={() => handleMobileClickEvent(item.slug)}>{item.section_name}</a>
                                    </div>
                                )
                            }
                        } else {
                            return (
                                <div className={itemClasses} key={index}>
                                    <p className='m-0 p-0 font-extrabold uppercase text-grey-600'>{item.section_name}</p>
                                </div>
                            )
                        }
                    });
                })
            }
        </>
    )
}