import * as React from "react";
import { useMediaQuery } from 'react-responsive'
import {FaBars} from "react-icons/fa";
import {TopNavProps} from "../models/TopNavProps";

export default function TopHeader(props: TopNavProps)
{
    //const [mobileMenuExpanded, setMobileMenuExpanded] = useState(false);
    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })

    return (
        <nav className="bg-white border-b-[1px] border-b-primary text-primary m-0 w-full pb-20 h-[80px] !z-50" style={{zIndex: 999}}>
            <div className="px-2 w-full">
                <div className="relative w-full">
                    <div className=''>

                        <div className='pt-2'>
                            <div className="flex flex-row md:w-auto w-full ml-auto mr-auto items-center text-center sm:items-start sm:text-left z-50">
                                <div className='md:w-auto w-full'>
                                    <img style={{height: 60, marginTop: 0, marginLeft: 'auto', marginRight: 'auto'}} className="block md:hidden lg:hidden mt-3 text-center mx-auto" src="/images/logo.png" alt="Crash Catch Logo" />
                                    <img style={{height: 60, marginTop: 0, marginLeft: 20}} className="hidden md:block lg:block" src="/images/logo.png" alt="Crash Catch Logo" />
                                </div>
                                <div className="hidden sm:block ml-3">
                                    <p className='text-4xl m-0 p-0'>
                                        Crash Catch
                                    </p>
                                    <p className='text-xs'>
                                        Crash reporting for every platform, every language, every developer
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </nav>
    )
}
