import * as React from "react";
import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {mapSectionsToSelectOptions, returnSlugValue} from "../../Utils/SectionHelper";
import {CreateSectionFormProps} from "../../models/CreateSectionFormProps";
import {HTTP_METHOD, sendRequest} from "../../Utils/APIManager";
import {
    Checkbox,
    Form,
    FormButtonContainer,
    PrimaryButton, returnErrorModal, SecondaryButton,
    Select,
    TextField,
    TextFieldType
} from "devso-react-library";

export default function CreateSectionForm(props: CreateSectionFormProps) {
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    const [sectionName, setSectionName] = useState('');
    const [parent, setParent] = useState('')
    const [clickable, setClickable] = useState(true);



    const handleSubmit = async (formData) => {

        try
        {
            console.log("Got form data", formData);
            formData.parent = formData.parent === ''
            || typeof formData.parent === typeof undefined ? '0' : formData.parent;
            formData.slug = returnSlugValue(formData.section_name);

            //If the new section is a child slug then ensure the path is /parent/child
            if (formData.parent !== "0")
            {
                formData.slug = `${formData.parent}/${formData.slug}`;
            }


            await sendRequest(formData, '/sections', HTTP_METHOD.POST)

            props.sectionCreatedSuccessfully();
            toast.success("Successfully created new section");
        }
        catch (err)
        {
            console.log("Error submitting new section", err);
            if (err?.response?.status === 422)
            {
                setFormErrors(err.response.data.errors);
            }
            else
            {
                props.setModalState({...returnErrorModal(err, "Failed to submit new section", props.setModalState)});
            }
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <Form errors={formErrors} loading={loading} handleSubmit={handleSubmit}>
            <TextField type={TextFieldType.TEXT} label='Section Name' placeholder='Enter Section Name'
                       api_field_name='section_name' value={sectionName} onChange={setSectionName} />

            <Select label='Parent' api_field_name='parent' value={parent}
                    onChange={setParent}>
                {
                    mapSectionsToSelectOptions(props.sections).map(section => {
                        if (section.value === "0")
                        {
                            return (
                                <option value="0">No Parent</option>
                            )
                        }
                        else
                        {
                            return (
                                <option value={section.value}>{section.label}</option>
                            )
                        }

                    })
                }
            </Select>

            <Checkbox label="Is Navigation Link" api_field_name='clickable'
                      value={null}
                      checked={clickable} checkStateChanged={setClickable}/>

            <FormButtonContainer loading={loading}>
                <PrimaryButton label="Create Section" />
                <SecondaryButton onClick={props.sectionCreationCancelled} label="Cancel"  />
            </FormButtonContainer>
        </Form>
    )
}