import React from 'react';
import 'devso-react-library/dist/styles.css'
import './BaseStyleSheet.css'
import {BrowserRouter, Routes, Route} from "react-router-dom";
import Home_old from "./Components/Home_old";
import {ScrollToTop} from "devso-react-library";
import Login from "./Components/Admin/Login";
import NotFound from "./Components/NotFound";
import {CrashCatch, CrashCatchProvider} from "crashcatchlib-reactjs";
import ErrorBoundary from "./Components/ErrorBoundary";
import AdminHelpContainer from "./Components/Admin/Article/AdminHelpContainer";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Logout from "./Components/Admin/Logout";
import Home from "./Components/Home";

function App() {

    const crash_catch = new CrashCatch();
    crash_catch.initialiseCrashCatch("25006391", "y9368vmatpxkjbzg4qwrucn5o", "2.0.0");

  return (
      <CrashCatchProvider value={crash_catch}>
          <ErrorBoundary>
              <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route index element={<Home />} />
                    <Route path='*' element={<Home />}/>
                    <Route path='/admin' element={<Login />} />
                    <Route path='/admin/article/*' element={<AdminHelpContainer /> }/>
                    <Route path='/admin/logout' element={<Logout /> } />

                    <Route path='*' element={<NotFound />} />
                </Routes>
              </BrowserRouter>
              <ToastContainer
                  position="top-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
              />
          </ErrorBoundary>
      </CrashCatchProvider>
  );
}

export default App;
