import {Section, SelectOptionValueAndLabel} from "../models/Sections";

export function returnSlugValue(sectionTitle: string) {
    return sectionTitle.toLowerCase().replaceAll(" ", "-")
}

export const getCurrentSlug = (path) => {
    const current_slug = path.replace("/admin/article", "");
    return current_slug.length > 0 ? current_slug.substring(1) : "/";
}

export function mapSectionsToSelectOptions(sections: Array<Section>, old_slug = null) : Array<SelectOptionValueAndLabel> {
    const options : Array<SelectOptionValueAndLabel> = [];

    options.push({
        label: 'No Parent',
        value: '0'
    })

    sections.forEach(section => {
        if (((old_slug !== null) && section.slug !== old_slug) || old_slug === null)
        {
            options.push({
                label: section.section_name,
                value: section.slug
            })
        }
    })
    return options;
}

export const returnFlatSectionArray = (section : Section) => {
    const array : Array<Section> = [
        {
            id: section.id,
            section_name: section.section_name,
            slug: section.slug,
            clickable: section.clickable,
            order_index: section.order_index,
            parent: section.parent,
            editable: section.editable,
            sub_items: []
        }
    ];

    section?.sub_items?.forEach(item => {
        array.push(item);
    })
    return array;
}