import * as React from "react";
import {FaProjectDiagram} from "react-icons/fa";
import {Link} from "react-router-dom";


const getCurrentPath = function()
{
    return window.location.href.substring(window.location.href.lastIndexOf("/")+1);
};

const returnFormattedDateStringFromDateObj = function(dateObj, includeTime = true)
{
    const day = dateObj.getDate().toString().padStart(2, '0');
    const month = (dateObj.getMonth() - 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear();
    const hours = dateObj.getUTCHours().toString().padStart(2, '0');
    const minutes = dateObj.getMinutes().toString().padStart(2, '0');

    if (includeTime) {
        return day + "-" + month + "-" + year + " " + hours + ":" + minutes;
    }
    else
    {
        return day + "-" + month + "-" + year;
    }
};

export const validationCompleted = function()
{
    const validInputCount = document.querySelectorAll("[data-input-valid='true']").length;
    const invalidInputCount = document.querySelectorAll("[data-input-valid='false']").length;

    if (validInputCount > 0 && invalidInputCount === 0)
    {
        disableSubmitButtons(false);
    }
    else
    {
        disableSubmitButtons(true);
    }
}

export const disableSubmitButtons = (disabled) => {
    const submitButtons : any = document.querySelector("button[type='submit']");
    if (submitButtons !== null)
    {
        submitButtons.disabled = disabled;
    }
}

export const convertFileInputToBase64 = file => {
    return new Promise(function(resolve, reject){
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export function useEnvironment()
{
    return process.env.NODE_ENV;
}

export function generateRandomString(length = 5)
{
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}


export {getCurrentPath, returnFormattedDateStringFromDateObj};
