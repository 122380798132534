import * as React from "react";
import {useState} from "react";
import {ViewAvailableVersionsProps} from "../../../models/Article";
import {
    DangerButton,
    PrimaryButton,
    returnDefaultModalState,
    returnErrorModal,
    SecondaryButton
} from "devso-react-library";
import {toast} from "react-toastify";
import {convertEloquentTimeToHumanReadable} from "../../../Utils/DataTimeManager";
import {HTTP_METHOD, sendRequest} from "../../../Utils/APIManager";

export default function ViewAvailableVersions(props: ViewAvailableVersionsProps) {

    const [selectedArticle, setSelectedArticle] = useState(-1);

    const handleDraftSelected = () => {
        if (selectedArticle === -1)
        {
            toast.warning("No draft article was selected");
        }
        else
        {
            props.draftSelected(props.drafts[selectedArticle]);
            props.setModalState({...returnDefaultModalState()});
        }
    }

    const confirmDeleteVersion = () => {
        if (selectedArticle === -1)
        {
            toast.warning("No draft article was selected");
        }
        else
        {
            if (props.drafts[selectedArticle].published)
            {
                toast.error("You can't delete the actively published article");
            }
            else
            {
                const tempModalState = returnDefaultModalState();
                tempModalState.open = true;
                tempModalState.setModalState = props.setModalState;
                tempModalState.title = "Are you sure";
                tempModalState.content = <p>Are you sure you want to delete version {props.drafts[selectedArticle].version_number}?</p>
                tempModalState.buttons = [
                    <DangerButton label="Yes...I'm sure" onClick={deleteVersion} />,
                    <SecondaryButton label='No' onClick={() => props.setModalState({...returnDefaultModalState()})} />
                ]
                props.setModalState({...tempModalState});
            }
        }
    }

    const deleteVersion = async () => {
        try
        {
            await sendRequest(null, `/article/${props.drafts[selectedArticle].id}`, HTTP_METHOD.DELETE);
            toast.success("Article version was successfully deleted");
            props.versionDeleted();
        }
        catch (err)
        {
            props.setModalState({...returnErrorModal(err, "Failed to delete version", props.setModalState)})
        }
    }

    return (
        <>
            <table className='w-full'>
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>Created At</th>
                        <th>Updated At</th>
                        <th>Title</th>
                        <th>Version Number</th>
                    </tr>
                </thead>
                <tbody>
                {
                    props.drafts.length > 0 ?
                        props.drafts.map((draft, index) => {
                            return (
                                <tr key={draft.id}>
                                    <td>
                                        <input type='radio' value={index} name='rdoSelectedDraft' onClick={() => setSelectedArticle(index)} />
                                    </td>
                                    <td>{convertEloquentTimeToHumanReadable(draft.created_at, true)}</td>
                                    <td>{convertEloquentTimeToHumanReadable(draft.updated_at, true)}</td>
                                    <td>{draft.title}</td>
                                    <td>{draft.version_number}</td>
                                </tr>

                            )
                        }) :
                        <tr>
                            <td colSpan={5} className='italic text-center'>
                                There are currently no drafts
                            </td>
                        </tr>
                }
                </tbody>
            </table>
            <div className='flex-row text-right my-2'>
                <SecondaryButton label='Dismiss' onClick={props.setModalState} />
                <DangerButton disabled={props.drafts.length === 0} label='Delete Version' onClick={confirmDeleteVersion} />
                <PrimaryButton disabled={props.drafts.length === 0} label='View Selected Draft' onClick={handleDraftSelected} />
            </div>
        </>
    )
}