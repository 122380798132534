import * as React from "react";

import {useContext, useEffect} from "react";
import Sidebar from "./Sidebar";
import ContentWrapper from "./ContentWrapper";
import Footer from "./Footer";
import Wrapper from "./Wrapper";
import {CrashCatchContext} from 'crashcatchlib-reactjs';
import {PrimaryButton, SecondaryButton} from "../../../devso-react-library";
import {Link} from "react-router-dom";

export default function NotFound() {
    const crash_catch = useContext(CrashCatchContext);

    useEffect(() => {
        try
        {
            throw Error("Page was not found");
        }
        catch (err)
        {
            crash_catch.reportCrash(err, "Low")
        }
    }, [])

    return (
        <div className='flex h-screen z-10'>
            <div className='ml-auto mr-auto text-center z-0 z-1'>
                <img src='/images/logo.png' alt='Crash Catch Logo' width={240} height={180} className=' ml-auto mr-auto text-center items-center' />
                <h1>Page Not Found</h1>
                <p className='font-bold'>
                    Sorry, the page you were looking for could not be found. Click the home button below to
                    go back to the Crash Catch documentation home page.
                </p>
                <p className='text-sm'>
                    Don't worry, we've been alerted to this and we'll have a fix available soon
                </p>

                <div className='text-center my-4'>
                    <Link to='/' className='bg-cta rounded px-3 py-2 text-white hover:bg-primary transition ease-in-out hover:underline'>
                        Back To Home
                    </Link>
                </div>
            </div>
        </div>
    )
}