import * as React from "react";
import {useState} from "react";

//import {UpdateSectionFormProps} from "../models/SidebarProps";
//import {mapSectionsToSelectOptions, returnSlugValue} from "../../JSFuncs/SectionHelper";
import {SectionUpdate} from "../../models/Sections";
import {UpdateSectionFormProps} from "../../models/SidebarProps";
import {mapSectionsToSelectOptions, returnSlugValue} from "../../Utils/SectionHelper";
import {HTTP_METHOD, sendRequest} from "../../Utils/APIManager";
import {
    Checkbox,
    Form,
    FormButtonContainer,
    PrimaryButton, SecondaryButton,
    Select,
    TextField,
    TextFieldType
} from "devso-react-library";

export default function UpdateSectionForm(props: UpdateSectionFormProps) {
    const [loading, setLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    const [sectionName, setSectionName] = useState(props.current_section.section_name);
    const [parent, setParent] = useState(props.current_section.parent)
    const [clickable, setClickable] = useState(props.current_section.clickable);

    const handleSubmit = async (data : SectionUpdate) => {
        data.oldSlug = props.current_section.slug;
        //Update the slug if the parent has changed
        if (data.slug !== props.current_section.slug)
        {
            data.slug = returnSlugValue(data.sectionName)
            if (data.parent !== "0")
            {
                data.slug = `${data.parent}/${data.slug}`;
            }
        }

        setLoading(true);
        try
        {
            await sendRequest(data, `/sections/${data.oldSlug}`, HTTP_METHOD.POST);
            props.handleSectionUpdated();
        }
        catch (err)
        {
            props.handleSectionUpdateFailed(err);
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <Form errors={formErrors} loading={loading} handleSubmit={handleSubmit}>
            <TextField type={TextFieldType.TEXT} label='Section Name' placeholder='Enter Section Name'
                       api_field_name='sectionName' value={sectionName} onChange={setSectionName} />

            <Select label='Parent' api_field_name='parent' value={parent}
                    onChange={setParent} /*options={mapSectionsToSelectOptions(props.all_sections, props.current_section.slug)}*/>
                {
                    mapSectionsToSelectOptions(props.all_sections, props.current_section.slug).map(section => {
                        return (
                            <option value={section.value}>{section.label}</option>
                        )
                    })
                }
            </Select>

            <Checkbox label="Is Navigation Link" api_field_name='clickable'
                      value={null}
                      checked={clickable} checkStateChanged={setClickable}/>

            <FormButtonContainer loading={loading}>
                <PrimaryButton label="Create Section" />
                <SecondaryButton onClick={props.sectionCreationCancelled} label="Cancel"  />
            </FormButtonContainer>
        </Form>
    )
}