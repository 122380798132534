import * as React from "react";
import {FaChartLine, FaProjectDiagram, FaUser} from 'react-icons/fa';
import {Link} from "react-router-dom";
import Cookies from 'js-cookie';

export default function DocsHome() {
    console.log("Token: ", Cookies.get("token"));
    return (
        <div className='m-3 h-full pb-10'>
            <h1>Crash Catch Documentation</h1>
            <p className='font-bold'>Explore our documentation and examples on how to easily integrate Crash Catch in to your projects</p>

            <div className='flex md:flex-row flex-col '>
                <div className='flex-1 mt-5 mb-5 md:mt-0 md:mb-0'>
                    <div className='md:pt-40'>
                        <h2>Simple Integration</h2>
                        <p>
                            Easily start receive crash and error alerts from any of your projects no matter your stack or platform you use.
                        </p>
                        <div className='md:text-left text-center !-ml-1 my-5'>
                            <Link className='btn btn-cta text-center' to={Cookies.get("token") !== undefined ? '/admin/article/getting-started' : '/getting-started'}>
                                Get Started
                            </Link>
                        </div>

                    </div>
                </div>
                <div className='flex-1'>
                    <img src='/images/hero_image.png' alt='Crash Catch Docs Here' />
                </div>
            </div>

            <div className='grid grid-cols-3 mt-5 w-auto sizzy-blue-2'>
                <div className='m-3 sizzy-green-2'>
                    <p className='font-bold'>
                        <Link  to='/manage-your-account/update-account-info'>
                            <FaUser className='inline mr-3' />Account
                        </Link>
                    </p>
                    <p>
                        Manage your account such as upgrading and downgrading your account
                    </p>
                </div>
                <div className='m-3 sizzy-green-2'>
                    <p className='font-bold'>
                        <Link to='/crash-and-error-reporting/viewing-crashes'>
                            <FaChartLine className='inline mr-3' />Reporting
                        </Link>
                    </p>
                    <p>
                        Manage your crash reports and assign team members for investigation
                    </p>
                </div>
                <div className='m-3 sizzy-green-2'>
                    <p className='font-bold'>
                        <Link to='/project-management/manage-projects'>
                            <FaProjectDiagram className='inline mr-3' /> Project Management
                        </Link>
                    </p>
                    <p>
                        Create and manage your projects
                    </p>
                </div>
            </div>


        </div>
    )
}