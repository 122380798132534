import * as React from "react";
import Wrapper from "./Wrapper";
import Sidebar from "./Sidebar";
import ContentWrapper from "./ContentWrapper";
import NotFound from "./NotFound";
import Footer from "./Footer";
import {useParams} from "react-router-dom";
import useArticle from "../hooks/useArticle";
import {useEffect} from "react";
import DocsHome from "./DocsHome";
import ViewArticle from "./ViewArticle";
import {Loading, LoadingSize, returnErrorComponent} from "devso-react-library";

export default function Home() {

    const params = useParams();
    const slug = params["*"];

    const {article, loading, error, getArticle} = useArticle(slug);

    useEffect(() => {
        (
            async () => {
                await getArticle(slug);
            }
        )()
    }, [params])

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    {
                        (
                            () => {

                                if (typeof slug === typeof undefined)
                                {
                                    return <DocsHome />
                                }
                                else if (loading)
                                {
                                    return <Loading size={LoadingSize.SMALL} />
                                }
                                else if (!loading && error !== null)
                                {
                                    if (error?.response?.status === 404)
                                    {
                                        return <NotFound />
                                    }
                                    else
                                    {
                                        return returnErrorComponent(error, "Failed to load article");
                                    }
                                }
                                else if (!loading && article !== null)
                                {
                                    return <ViewArticle {...article} />
                                }
                                else
                                {
                                    console.log("Returning null interface");
                                    return null;
                                }
                            }
                        )()
                    }
                </ContentWrapper>
            </div>
            <Footer />
        </Wrapper>
    )
}