import * as React from "react";
import {useEffect, useState} from "react";
import {sendRequest} from "../Utils/APIManager";
import {Article, GetArticleAPIResponse, GetArticleDataResponse} from "../models/Article";

export default function useArticle(slug: string, setPublishedArticle = null) {

    const [article, setArticle] = useState<GetArticleDataResponse & Article>(null);
    const [loading, setLoading] = useState(false);
    const [error, setErrors] = useState(null);
    const [currentPublishedArticled, setCurrentPublishedArticle] = useState(null);

    const getArticle = async (slug: string, setPublishedArticle = null) => {
        if (typeof slug === typeof undefined)
        {
            return;
        }
        try
        {
            setLoading(true);
            setErrors(null);
            setArticle(null);
            const response : GetArticleAPIResponse & Article = await sendRequest(null, `/article/${slug}`);
            setArticle(response.data);
            if (setPublishedArticle !== null)
            {
                //setPublishedArticle(response.data.published_article);
                console.log("Setting the current published article to:", response.data);
                setCurrentPublishedArticle(response.data.published_article);
            }

        }
        catch (err)
        {
            setErrors(err);
        }
        finally
        {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (currentPublishedArticled?.published_article?.id !== article?.published_article.id)
        {
            setPublishedArticle(article.published_article);
        }
    }, [])

    useEffect(() => {
        (
            async () => {
                await getArticle(slug, setPublishedArticle);
            }
        )()
    }, [])

    return {article, loading, error, getArticle};
}