import * as React from "react";
import {
    FaBars,
    FaBug, FaCaretUp,
    FaCog,
    FaCreditCard,
    FaExternalLinkAlt, FaHamburger,
    FaList,
    FaProjectDiagram, FaTable,
    FaUserAlt,
    FaUsers
} from "react-icons/fa";
import { useMediaQuery } from 'react-responsive'
import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import UserSidebar from "./UserSidebar";


export default function Sidebar(props) {

    const location = useLocation();

    const [expanded, setExpanded] = useState(false);

    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 700px)'
    })

    useEffect(() => {
        if (isTabletOrMobileDevice)
        {
            const element = document.getElementById("nav_container");
            element.style.display = "none";
        }

    }, [])

    useEffect(() => {
        window.setTimeout(function() {
            if (!expanded && isTabletOrMobileDevice)
            {
                const element = document.getElementById("nav_container");
                element.style.display = "none";
            }
        }, 300)
        if (expanded && isTabletOrMobileDevice)
        {
            const element = document.getElementById("nav_container");
            element.style.display = "block";
        }

    }, [expanded])

    return (
        <div className='!z-0 md:relative z-40 md:h-screen w-full md:w-[250px] bg-white border-r-[1px] border-r-gray-300 md:p-4 md:flex-none flex flex-row'>
            <div className='w-full hidden md:block h-[calc(100vh-170px)] h-full overflow-y-auto overflow-x-hidden'>

                <UserSidebar slug={props.slug} mobileSidebarOpen={expanded} setMobileSidebarOpen={setExpanded} />
            </div>
            <div id='nav_container' className='absolute bg-white w-full md:hidden block h-[calc(100vh-90px)] overflow-y-auto px-2 shadow-md shadow-primary/80'>
                <UserSidebar slug={props.slug} mobileSidebarOpen={expanded} setMobileSidebarOpen={setExpanded} />
            </div>
            <div className='md:hidden block text-white text-right cursor-pointer !absolute left-0 -mt-14 ml-3'
                onClick={() => setExpanded(!expanded)}>
                {
                    !expanded ? <FaBars className='text-primary text-right float-right mt-3 mr-5' />
                        : <FaCaretUp className='text-primary text-right float-right mt-3 mr-5' />
                }

            </div>
        </div>
    )
}