import * as React from "react";
import {useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {
    Form,
    FormButtonContainer,
    ModalDialog,
    ModalState,
    PrimaryButton,
    returnErrorModal,
    TextField,
    TextFieldType
} from "devso-react-library";
import Wrapper from "../Wrapper";
import ContentWrapper from "../ContentWrapper";
import {HTTP_METHOD, sendRequest} from "../../Utils/APIManager";


export default function Login() {
    const [modalState, setModalState] = useState<ModalState>(null);
    const [formLoading, setFormLoading] = useState(false);
    const [formErrors, setFormErrors] = useState(null);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState(null);

    const navigate = useNavigate();

    const submitLogin = async (postData) => {

        try
        {
            setFormLoading(true);
            setFormErrors(null);
            const response = await sendRequest(postData, `/auth/login`, HTTP_METHOD.POST);
            navigate('/admin/article')
        }
        catch (err)
        {
            setModalState({...returnErrorModal(err, "Failed to login", setModalState)});
        }
        finally {
            setFormLoading(false);
        }
    }

    return (
        <Wrapper>
            <ContentWrapper>

                <div className="grid h-screen place-items-center w-3/4 h-[320px] mx-auto text-center !ml-20">
                    {
                        loginError !== null ? <div className='w-3/4 text-center ml-auto mr-auto p-2 rounded bg-warning border border-warning-dark border-1'>
                            <p>{loginError}</p>
                        </div> : null
                    }

                    <div className='flex flex-row mt-2 mx-auto text-center'>
                        <div className='text-right items-end mr-10'>
                            <img src='/images/logo.png' alt='Crash Catch Logo' width={180} height={180} />
                        </div>
                        <div className='mt-2'>
                            <h1>Crash Catch</h1>
                            <p className='font-bold text-xl'>
                                Crash Reporting for Every Developer, Every Platform and Every Programming Language
                            </p>
                        </div>
                    </div>

                    <Form className='w-3/4' handleSubmit={submitLogin} errors={formErrors} loading={formLoading}>
                        <TextField type={TextFieldType.EMAIL} label='Email' placeholder='Enter Email Address'
                                   api_field_name='email' value={email} onChange={setEmail} />

                        <TextField type={TextFieldType.PASSWORD} label='Password' placeholder='Enter Password'
                                   api_field_name='password' value={password} onChange={setPassword} />

                        <FormButtonContainer loading={formLoading}>
                            <PrimaryButton label="Login" />
                        </FormButtonContainer>
                    </Form>

                    <p className='text-center text-grey-400'>
                        In order to login you need to have the prior permission of Devso to gain access.
                        All logins are recorded
                    </p>
                </div>


            </ContentWrapper>
            <ModalDialog {...modalState} />
        </Wrapper>
    )
}