import * as React from "react";
import {useState} from "react";
import {
    Form,
    FormButtonContainer, FormInputDirection,
    PrimaryButton, returnDefaultModalState,
    SecondaryButton,
    TextField,
    TextFieldType
} from "devso-react-library";
import {CreateOrUpdateMetaTagsProps} from "../../../models/Article";

export default function CreateOrUpdateMetaTags(props: CreateOrUpdateMetaTagsProps) {


    const [title, setTitle] = useState(props?.article?.meta_title);
    const [keywords, setKeywords] = useState(props?.article?.meta_keywords);
    const [description, setDescription] = useState(props?.article?.meta_description);

    const handleSubmit = (formData) => {
        props.metaSaved(title, keywords, description);
        props.setModalState({...returnDefaultModalState()});
    }

    return (
        <Form loading={false} errors={null} handleSubmit={handleSubmit} direction={FormInputDirection.Vertical}>
            <TextField type={TextFieldType.TEXT} api_field_name='title' label='Title'
                       onChange={setTitle} value={title} placeholder='Meta Title' />
            <TextField type={TextFieldType.TEXT} api_field_name='keywords' label='Keywords'
                       onChange={setKeywords} value={keywords} placeholder='Meta Keywords (comma separated)' />
            <TextField type={TextFieldType.TEXT} api_field_name='description' label='Description'
                       onChange={setDescription} value={description} placeholder='Set description' />

            <FormButtonContainer loading={false}>
                <SecondaryButton label='Cancel' onClick={() => props.setModalState({...returnDefaultModalState()})} />
                <PrimaryButton label='Save Meta Tags' />
            </FormButtonContainer>
        </Form>
    )
}