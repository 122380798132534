import * as React from "react";
import {Section, AdminSectionListProps} from "../../models/Sections";
import {useEffect, useRef} from "react";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {FaEdit, FaTrash} from "react-icons/fa";

import {toast} from "react-toastify";
import {PrimaryButton, returnDefaultModalState, returnErrorModal, SecondaryButton} from "devso-react-library";
import UpdateSectionForm from "./UpdateSectionForm";
import {returnFlatSectionArray} from "../../Utils/SectionHelper";
import {Link} from "react-router-dom";
export default function AdminSectionList (props: AdminSectionListProps) {
    const currentIndex = useRef(-1);


    const flattenArray = (section: Section) => {
        const array = [];

        const parentSection: Section = {
            slug: section.slug,
            section_name: section.section_name,
            parent: section.parent,
            clickable: section.clickable,
            order_index: section.order_index,
            editable: section.editable,
            sub_items: []
        }

        array.push(parentSection);

        section.sub_items.forEach(item => {
            if (typeof item !== typeof undefined) {
                array.push(item);
            }
        })

        return array;
    }

    const unflattenArray = (flattenedArray) => {
        let array = [];

        flattenedArray.forEach((item, index) => {
            if (typeof item !== typeof undefined) {
                if (index === 0) //This will be the parent
                {
                    array.push(item);
                    array[0].sub_items = [];
                }
                else //This will be all children of the sub_item
                {
                    array[0].sub_items.push(item);
                }
            }
        })

        return array;
    }


    /**
     * Process the reordering when dragging completes, the result
     * comes from the beautify-react-dnd on the drag end callback
     * confirmed says whether or not any user specific action requring
     * confirmation is done. e.g. if a parent section is moved to
     * another droppable, the user is asked whether it should become a child
     * of the destination droppable or a new parent. In this case a modal dialog
     * will be called and if the user says yes to confirm the change, then this
     * method is recalled with the confirmed flag to true.
     * @param result
     */
    const processOnDragEnd = async (result) => {
        if (!result.destination) return;

        const sourceParentSectionIndex = props.sections.findIndex(section => section.slug === result.source.droppableId);
        const destinationParentSectionIndex = props.sections.findIndex(section => section.slug === result.destination.droppableId);

        if (result.source.droppableId === result.destination.droppableId)
        {
            if (result.source.index === 0 && result.destination.index > 0)
            {
                //A parent section cannot be moved to a child
                const tempModalState = returnDefaultModalState();
                tempModalState.open = true;
                tempModalState.title = "Parent section can't become child"
                tempModalState.content = <p>
                    A parent section cannot be moved so that it becoms a child
                </p>
                /*tempModalState.primaryButtonFunc = () => {
                    const temp = returnDefaultModalStatus();
                    props.setModalState({...temp});
                }
                tempModalState.primaryButtonLabel = "OK";*/
                tempModalState.buttons = [
                    <SecondaryButton label='OK'
                         onClick={() => props.setModalState({...returnDefaultModalState()})} />
                ]
                props.setModalState({...tempModalState});

                return
            }
            let items = flattenArray(props.sections[sourceParentSectionIndex]);

            const source_index = result.source.index;
            const destination_index = result.destination.index;

            const [reorderedItem] = items.splice(source_index, 1);

            items.splice(destination_index, 0, reorderedItem);

            items = updateOrderIndexAttributeByOrder(items);

            currentIndex.current = -1;

            const updated_section = unflattenArray(items);

            //update_section only contains the array of the element being reordered,
            //this fully recreates the original structure to pass back to the sidebar parent
            const full_sections = [];
            props.sections.forEach((section, index) => {
                if (index !== sourceParentSectionIndex)
                {
                    full_sections.push(section);
                }
                else
                {
                    full_sections.push(updated_section[0]);
                }
            })

            //Pas the full structure of all sections as the first paraeter, this is used to
            //set the ordered sections, so the view has the update model, without needing to
            //refetch from the database, the second parameter contains just the section being updated
            //so that it can perform the database update
            props.sectionOrderUpdated(full_sections, updated_section, props.sections);
        }
        else
        {
            let source_items = flattenArray(props.sections[sourceParentSectionIndex]);
            let destination_items = flattenArray(props.sections[destinationParentSectionIndex]);

            //Sub items cannot be dragged from one parent to another parent
            if (result.source.index > 0)
            {
                const tempModalState = returnDefaultModalState();
                tempModalState.open = true;
                tempModalState.title = "Invalid Drag";
                tempModalState.content = <><p>
                    You cannot drag a sub section from one parent to a different parent.
                </p>
                    <p>
                        To do this, edit the section instead by clicking the <FaEdit className='inline'/> icon instead
                    </p>
                </>
                tempModalState.buttons = [
                    <SecondaryButton label='OK'
                         onClick={() => props.setModalState({...returnDefaultModalState()})} />
                ]
                /*tempModalState.primaryButtonLabel = "OK";
                tempModalState.primaryButtonFunc = () => {
                    const temp = returnDefaultModalStatus();
                    props.setModalState({...temp});
                }*/
                props.setModalState({...tempModalState});
                return;
            }

            const source_index = result.source.index;
            const destination_index = result.destination.index;

            if (source_index === 0 && (destination_index > 0 && destination_index < destination_items.length))
            {
                //A parent section cannot be moved to a child
                const tempModalState = returnDefaultModalState();
                tempModalState.open = true;
                tempModalState.title = "Parent section can't become child"
                tempModalState.content = <p>
                    A parent section cannot be moved so that it becoms a child
                </p>
                tempModalState.buttons = [
                    <SecondaryButton label='OK'
                             onClick={() => {props.setModalState({...returnDefaultModalState()})}} />
                ]
                /*tempModalState.primaryButtonFunc = () => {
                    const temp = returnDefaultModalStatus();
                    props.setModalState({...temp});
                }
                tempModalState.primaryButtonLabel = "OK";*/
                props.setModalState({...tempModalState});

                return
            }

            const [reorderedItem] = source_items.splice(source_index, 1);

            reorderedItem.parent = result.destination.droppableId;
            reorderedItem.sub_items = [];



            source_items = updateOrderIndexAttributeByOrder(source_items);

            //If the destination index is equal to 0, then we're adding the parent section
            //as a brand new section
            let parent_updated = false;
            if (destination_index === 0 || destination_index === destination_items.length)
            {
                parent_updated = true;
                destination_items = [];
                reorderedItem.parent = "0";
                if (result.destination.index === 0)
                {
                    reorderedItem.insertIndex = destinationParentSectionIndex;
                    reorderedItem.insertBefore = true;
                }
                else
                {
                    reorderedItem.insertIndex = destinationParentSectionIndex;
                    reorderedItem.insertBefore = false;
                }
                reorderedItem.sub_items = [];
                destination_items.push(reorderedItem);

            }
            else
            {
                destination_items.splice(destination_index, 0, reorderedItem);
            }
            destination_items = updateOrderIndexAttributeByOrder(destination_items);

            const reordered_source = unflattenArray(source_items);
            const reordered_destination = unflattenArray(destination_items);

            let new_sections = null;
            if (!parent_updated)
            {
                new_sections = reordered_source.concat(reordered_destination);
            }
            else
            {
                new_sections = [];
                //Rebuild all of the parents so the orderIndex can be updated
                //to shift the ordering of the parents in the DB
                let reorderedItemAdded = false;
                props.sections.forEach((section, index) => {

                    if (reorderedItem.insertIndex === index && reorderedItem.insertBefore )
                    {

                        if (!reorderedItemAdded)
                        {
                            new_sections.push(reordered_destination[0]);
                            reorderedItemAdded = true;
                        }
                        if (section.slug !== reorderedItem.slug) {
                            new_sections.push(section);
                        }

                    }
                    else if (reorderedItem.insertIndex === index && !reorderedItem.insertBefore)
                    {
                        if (reorderedItem.slug !== section.slug) {
                            new_sections.push(section);
                        }
                        if (!reorderedItemAdded)
                        {
                            new_sections.push(reordered_destination[0]);
                            reorderedItemAdded = true;
                        }
                    }
                    else
                    {
                        if (reorderedItem.slug !== section.slug) {
                            new_sections.push(section);
                        }
                    }
                })

                new_sections.forEach((section, index) =>{
                    section.orderIndex = index;
                });

            }


            //update_section only contains the array of the element being reordered,
            //this fully recreates the original structure to pass back to the sidebar parent
            let full_sections = [];
            let reorderedItemAdded = false;
            props.sections.forEach((section, index) => {

                if (reorderedItem.insertIndex === index && reorderedItem.insertBefore )
                {

                    if (!reorderedItemAdded)
                    {
                        full_sections.push(reordered_destination[0]);
                        reorderedItemAdded = true;
                    }
                    if (section.slug !== reorderedItem.slug) {
                        full_sections.push(section);
                    }

                }
                else if (reorderedItem.insertIndex === index && !reorderedItem.insertBefore)
                {
                    if (reorderedItem.slug !== section.slug) {
                        full_sections.push(section);
                    }
                    if (!reorderedItemAdded)
                    {
                        full_sections.push(reordered_destination[0]);
                        reorderedItemAdded = true;
                    }
                }
                else
                {
                    if (reorderedItem.slug !== section.slug) {
                        full_sections.push(section);
                    }
                }
            });

            full_sections = full_sections.filter(section => {
                return section !== undefined;
            })

            await props.sectionOrderUpdated(full_sections, new_sections, props.sections, parent_updated);
            currentIndex.current = -1;
        }
    }

    const updateOrderIndexAttributeByOrder = (array, updateParentIndex: boolean = false) => {
        array.forEach((item, index) => {
            if (typeof item !== typeof undefined) {
                if (typeof item.sub_items !== typeof undefined) {
                    item.sub_items = [];
                }
                //Only update the index on the child
                if (item.parent !== "0") {
                    if (index > 0) //Bit of a hack to prevent a sub item becoming -1 order - don't see how that happens
                    {
                        item.orderIndex = index -1;
                    }
                    else
                    {
                        item.orderIndex = 0;
                    }
                }
                else
                {
                    if (updateParentIndex)
                    {
                        item.orderIndex = index;
                    }
                }
            }
        });
        return array;
    }


    const handleEditSection = (section: Section, isParent: boolean) => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Edit Section";
        tempModalState.content = <UpdateSectionForm current_section={section}
                                                 all_sections={props.sections} parentSection={isParent}
                                                 handleSectionUpdated={handleSectionUpdated}
                                                 handleSectionUpdateFailed={handleSectionUpdateFailed}
                                                 sectionCreationCancelled={cancelEditSection} setModalState={props.setModalState} />
        //tempModalState.primaryButtonLabel = null;
        //tempModalState.primaryButtonFunc = null;
        props.setModalState({...tempModalState});
    }

    const handleSectionUpdated = () => {
        props.refetchSections();
        const tempModalState = returnDefaultModalState();
        props.setModalState({...tempModalState});
        toast.success("Section updated successfully");
    }

    const handleSectionUpdateFailed = (err) => {
        const tempModalState = returnErrorModal(err, "Error Updating Section", props.setModalState);
        props.setModalState({...tempModalState});
    }

    const cancelEditSection = () => {
        const temp = returnDefaultModalState();
        props.setModalState({...temp});
    }

    const handleOnDragEnd = async (result) => {

        await processOnDragEnd(result);
    }

    useEffect(() => {
        currentIndex.current = -1;
    }, [props.sections])

    if (props.sections.length > 0)
    {

        return (
            <>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                    {
                        props.sections.map(section => {
                            const flattenedArray = returnFlatSectionArray(section);
                            return (
                                <Droppable key={flattenedArray[0].slug} droppableId={flattenedArray[0].slug}>
                                    {(provided) => (
                                        <div className='m-0 p-0' {...provided.droppableProps} ref={provided.innerRef}>
                                            {
                                                flattenedArray.map((item, index) => {
                                                    currentIndex.current++;
                                                    let itemClasses = '';
                                                    let isParent = false;
                                                    if (index > 0)
                                                    {
                                                        isParent = false;
                                                        itemClasses = 'pl-8 pt-2 pb-2 hover:bg-grey-300 rounded pl-2 w-full grid grid-cols-2 hover:bg-grey-300';
                                                    }
                                                    else
                                                    {
                                                        isParent = true;
                                                        //This has to be a parent so reset the current index to 0
                                                        //so that each draggable is an increment from the parent
                                                        //instead of being a continuous number for all sections
                                                        //as the way the structure of the arrays are processed
                                                        //the indexes would be incorrect
                                                        currentIndex.current = 0;
                                                        itemClasses = 'pl-0 pt-2 pb-2 rounded pl-2 w-full grid grid-cols-2 hover:bg-grey-300';

                                                    }
                                                    if (item.clickable)
                                                    {
                                                        if (item.slug === props.slug)
                                                        {
                                                            itemClasses += ' bg-grey-300 border-grey-400  border-1 hover:bg-grey-300'
                                                        }
                                                        return (
                                                            <Draggable className='p-2' key={item.slug} draggableId={item.slug} index={currentIndex.current} >
                                                                {(provided) => (
                                                                    <div className={itemClasses} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                                                        <Link to={'/admin/article/' + item.slug}>{item.section_name}</Link>
                                                                        {
                                                                            item.editable ?
                                                                                <div className='opacity-0 hover:opacity-100 transition-opacity  pr-2 inline inline text-right w-full'>
                                                                                    <FaEdit onClick={() => handleEditSection(item, isParent)} data-tip='Edit Section ' className='inline mr-2 text-right justify-items-end cursor-pointer' />

                                                                                    <FaTrash data-tip='Remove section and all articles' onClick={() => props.showDeleteSectionModal(item)}
                                                                                             className='text-red-600 inline text-right justify-items-end justify-end cursor-pointer' />
                                                                                </div> : null

                                                                        }

                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    }
                                                    else
                                                    {
                                                        return (
                                                            <Draggable className='' key={item.slug} draggableId={item.slug} index={currentIndex.current} >
                                                                {(provided) => (
                                                                    <div className={itemClasses} ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} >
                                                                        <p className='m-0 p-0 font-extrabold'>{item.section_name}</p>
                                                                        {
                                                                            item.editable ?
                                                                                <div className='opacity-0 hover:opacity-100 transition-opacity  pr-2 inline inline text-right w-full'>
                                                                                    <FaEdit onClick={() => handleEditSection(item, isParent)}
                                                                                            data-tip='Edit Section' className='inline mr-2 text-right justify-items-end cursor-pointer' />

                                                                                    <FaTrash onClick={() => props.showDeleteSectionModal(item)}
                                                                                             className='text-red-600 inline text-right justify-items-end justify-end cursor-pointer' />
                                                                                </div> : null
                                                                        }

                                                                    </div>
                                                                )}
                                                            </Draggable>
                                                        )
                                                    }

                                                })
                                            }
                                            {provided.placeholder}
                                        </div>

                                    )}
                                </Droppable>
                            )
                        })
                    }

                </DragDropContext>
            </>
        )
    }
    else
    {
        return (
            <>
                <div className='pl-0 pt-2 pb-2 rounded pl-2 w-full hover:bg-grey-300'>
                    <Link className= 'pt-2 pb-2 hover:bg-grey-300 rounded m-0 p-0 font-extrabold w-full' to='/article'>
                        Home
                    </Link>
                </div>
                <p className='italic font-bold text-center'>There are currently no other sections</p>
            </>
        )
    }
}