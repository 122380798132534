import * as React from "react";
import Wrapper from "../../Wrapper";
import AdminSidebar from "../AdminSidebar";
import ContentWrapper from "../../ContentWrapper";
import {useParams} from "react-router-dom";
import DocsHome from "../../DocsHome";
import useArticle from "../../../hooks/useArticle";
import {
    Loading,
    LoadingSize,
    returnDefaultModalState,
    returnErrorComponent,
    SecondaryButton,
    DangerButton, ModalDialog, ModalState, Banner, BannerType
} from "devso-react-library";
import CreateUpdateArticle from "./CreateUpdateArticle";
import {useEffect, useRef, useState} from "react";
import {Article} from "../../../models/Article";
import ViewArticle from "../../ViewArticle";
import {FaCog, FaEdit, FaEye, FaInfoCircle} from "react-icons/fa";
import ViewAvailableVersions from "./ViewAvailableVersions";
import CreateOrUpdateMetaTags from "./CreateOrUpdateMetaTags";

export default function AdminHelpContainer() {

    const params = useParams();
    const slug = params["*"];

    const [selectedArticle, setSelectedArticle] = useState<Article>(null);
    const {article, loading, error, getArticle} = useArticle(slug, setSelectedArticle);


    const [editingArticle, setEditingArticle] = useState(false);
    const [modalState, setModalState] = useState<ModalState>(null);
    const [newerDraftAvailable, setNewerDraftAvailable] = useState(false);

    const [metaTitle, setMetaTitle] = useState('');
    const [metaKeywords, setMetaKeywords] = useState('');
    const [metaDescription, setMetaDescription] = useState('');

    const confirmCancelEdit = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.setModalState = setModalState;
        tempModalState.title = "Are you sure";
        tempModalState.content = <p>Are you sure you want to cancel editing this article? Any changes will not be saved</p>
        tempModalState.buttons = [
            <DangerButton label="Yes...I'm sure" onClick={cancelEdit} />,
            <SecondaryButton label="No" onClick={() => setModalState({...returnDefaultModalState()})} />
        ]
        setModalState({...tempModalState});
    }

    const cancelEdit = () => {
        setEditingArticle(false);
        setModalState({...returnDefaultModalState()})
    }

    const switchToLatestDraft = () => {
        setSelectedArticle(article.draft_articles[0]);
    }

    const switchToPublished = () => {
        setSelectedArticle(article.published_article);
    }

    const viewAvailableVersions = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.setModalState = setModalState;
        tempModalState.largeModal = true;
        tempModalState.title = "Available Drafts";
        tempModalState.content = <ViewAvailableVersions drafts={article.draft_articles}
                                                        draftSelected={setSelectedArticle} setModalState={setModalState} versionDeleted={versionDeleted} />
        setModalState({...tempModalState});
    }

    const versionDeleted = async () => {
        await getArticle(slug);
        setModalState({...returnDefaultModalState()});
    }

    useEffect(() => {
        if (article !== null)
        {
            if (article.published_article !== null)
            {
                if (selectedArticle?.id !== article?.published_article?.id)
                {
                    setSelectedArticle(article.published_article)
                }
            }
            else if (article.draft_articles !== null)
            {
                setSelectedArticle(article?.draft_articles[0]);
            }
        }
    }, [article])

    useEffect(() => {
        if (selectedArticle !== null)
        {
            const result = article.draft_articles.filter(a => a.version_number > selectedArticle.version_number).length > 0;
            if (newerDraftAvailable !== result)
            {
                setNewerDraftAvailable(result);
            }
        }
    }, [selectedArticle])

    useEffect(() => {
        (
            async () => {
                setSelectedArticle(null);
                setEditingArticle(false);
                await getArticle(slug);
            }
        )()

    }, [params]);



    return (
        <Wrapper>
            <AdminSidebar />
            <div className='w-full'>
                <ContentWrapper>
                    {
                        (
                            () => {
                                if (slug === "" || slug === "/")
                                {
                                    return <DocsHome />
                                }
                                else if (loading)
                                {
                                    return <Loading size={LoadingSize.SMALL} label='Retrieving Article...Please wait' />
                                }
                                else if (!loading && article !== null)
                                {
                                    if (selectedArticle !== null && !editingArticle)
                                    {
                                        return (
                                            <>
                                                {
                                                    newerDraftAvailable ?
                                                    <Banner content={<>
                                                        Newer draft version is available<br />
                                                        <span className='hover:underline text-primary cursor-pointer' onClick={switchToLatestDraft}>click
                                                            to view latest draft</span>
                                                        </>}/>: null
                                                }

                                                {
                                                    selectedArticle.published ?
                                                        <Banner content={<>
                                                                You are viewing a <span className='font-bold'>
                                                                a live article
                                                            </span>
                                                            </>}
                                                            icon={FaInfoCircle}
                                                            type={BannerType.WARNING}
                                                        /> :
                                                        <Banner content={<>
                                                            You are viewing version <span className='font-bold'>{selectedArticle.version_number}</span>&nbsp;
                                                            of a <span className='font-bold'>draft</span> article.<br />
                                                            <span className='hover:underline text-primary cursor-pointer' onClick={switchToPublished}>click
                                                            to view published article</span>
                                                        </>}
                                                                icon={FaInfoCircle}
                                                                type={BannerType.INFO} />
                                                }
                                                <div className='text-right my-4'>
                                                    <SecondaryButton label={<><FaEye />Manage Available Versions</>} onClick={viewAvailableVersions} />
                                                    <SecondaryButton className={`${selectedArticle?.version_number < article?.published_article?.version_number ? "hidden" : ""}`} label={<><FaEdit />Edit Article</>} onClick={() => setEditingArticle(true)} />
                                                </div>

                                                <ViewArticle {...selectedArticle} />
                                            </>
                                        )
                                    }
                                    else if (selectedArticle !== null && editingArticle)
                                    {
                                        return (
                                            <>
                                                <CreateUpdateArticle slug={slug} article={selectedArticle} cancelEdit={confirmCancelEdit} />
                                            </>
                                        )
                                    }
                                    else
                                    {
                                        console.log("Returning Null 1");
                                        return null
                                    }
                                }
                                else if (!loading && error !== null)
                                {
                                    if (error?.response?.status === 404)
                                    {
                                        return (
                                            <>
                                                <Banner content="This article does not exist"
                                                    icon={FaInfoCircle}/>
                                                <CreateUpdateArticle slug={slug} cancelEdit={confirmCancelEdit} />
                                            </>
                                        )
                                    }
                                    else
                                    {
                                        return returnErrorComponent(error, "Failed to get article");
                                    }
                                }
                                else
                                {
                                    console.log("Returning null 2");
                                    return null;
                                }

                            }
                        )()
                    }

                </ContentWrapper>
            </div>
            <ModalDialog {...modalState} />
        </Wrapper>
    )
}
