import * as React from "react";
import {useEffect, useState} from "react";
import {
    ButtonMenuItem,
    Form,
    ModalDialog,
    ModalState,
    PrimaryButton,
    returnDefaultModalState,
    returnErrorModal,
    SecondaryButton,
    SmallButtonWithMenu,
    TextField,
    TextFieldType,
    Banner, BannerType
} from "devso-react-library";
import {convertFileInputToBase64} from "../../../Utils/HelperFunctions";
import {HTTP_METHOD, sendRequest} from "../../../Utils/APIManager";
import MarkdownIt from 'markdown-it';
import MdEditor from 'react-markdown-editor-lite';
// import style manually
import 'react-markdown-editor-lite/lib/index.css';
import {ImageUploadResponse} from "../../../models/Images";
import {CreateArticleProps} from "../../../models/Article";
import {toast} from "react-toastify";
import {FaCog, FaExclamation, FaExclamationTriangle, FaTimes} from "react-icons/fa";
import CreateOrUpdateMetaTags from "./CreateOrUpdateMetaTags";


export default function CreateUpdateArticle(props: CreateArticleProps) {
    const mdParser = new MarkdownIt(/* Markdown-it options */);
    const [loading, setLoading] = useState(false);
    const [formError, setFormError] = useState(null);

    const [title, setTitle] = useState(props.article?.title);
    const [postContent, setPostContent] = useState(props.article?.content);
    const [modalState, setModalState] = useState<ModalState>(null);

    const [metaTitle, setMetaTitle] = useState(props?.article?.meta_title);
    const [metaKeywords, setMetaKeywords] = useState(props?.article?.meta_keywords);
    const [metaDescription, setMetaDescription] = useState(props?.article?.meta_description);

    function handleEditorChange({ html, text }) {
        setPostContent(text);
    }

    const handleImageUpload = async(file) => {
        try
        {
            const base64 = await convertFileInputToBase64(file);
            console.log("File", file);

            const data = {
                file_name: file.name,
                file_size: file.size,
                file_type: file.type,
                content: base64
            }

            const response : ImageUploadResponse = await sendRequest(data, '/images', HTTP_METHOD.POST);
            return `/backend/api/images/${response.data.image_id}`;
        }
        catch (err)
        {
            setModalState({...returnErrorModal(err, "Failed to Upload Image", setModalState)})
        }
    }

    const saveAsDraftOptions: Array<ButtonMenuItem> = [
        {
            label: "Update Current Draft",
            labelIdentifier: "update-current-draft",
            itemClick: () => {
                confirmSavingOverCurrentDraftVersion()
                //await submitArticle(false, false);
            }
        },
        {
            label: "Save As New Version",
            labelIdentifier: "save-as-new-version",
            itemClick: async () => {

                const tempModalState = returnDefaultModalState();
                tempModalState.open = true;
                tempModalState.setModalState = setModalState;
                tempModalState.title = "Create New Version";
                tempModalState.content = <p>
                    Are you sure you want to save this draft <span className='font-bold'>as a new version</span>?
                </p>
                tempModalState.buttons = [
                    <PrimaryButton label="Yes...I'm sure" onClick={async () => {
                        await submitArticle(false, true);
                    }} />,
                    <SecondaryButton label='No' onClick={() => {
                        setModalState({...returnDefaultModalState()})
                    }} />
                ];
                setModalState({...tempModalState});


                //await submitArticle(false, true);
            }
        }
    ]

    const confirmSavingOverCurrentDraftVersion = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.setModalState = setModalState;
        tempModalState.title = "Update Current Draft"
        tempModalState.content = <p>
            Are you sure you want to save changes to the current draft <span className='font-bold'>without</span>&nbsp;
            creating a new version
        </p>
        tempModalState.buttons = [
            <PrimaryButton label="Yes...I'm sure" onClick={async () => {
                await submitArticle(false, false);
            }} />,
            <SecondaryButton label='No' onClick={() => {
                setModalState({...returnDefaultModalState()})
            }} />
        ]
        setModalState({...tempModalState});
    }

    const submitArticle = async (published: boolean, saveAsNewDraftVersion: boolean = false) => {

        try
        {
            const data = {
                title: title,
                content: postContent,
                slug: props.slug,
                meta_title: metaTitle,
                meta_keywords: metaKeywords,
                meta_description: metaDescription,
                published: published ? "1" : "0",
                saveAsNewDraftVersion: saveAsNewDraftVersion ? "1" : "0"
            }

            if (props.article === null)
            {
                await sendRequest(data, '/article', HTTP_METHOD.POST);
            }
            else
            {
                await sendRequest(data, `/article/${props.article.id}`, HTTP_METHOD.PUT);
            }

            if (published)
            {
                toast.success("Successfully published article");
            }
            else if (!published && saveAsNewDraftVersion)
            {
                toast.success("Successfully saved article as new draft version");
            }
            else if (!published && !saveAsNewDraftVersion)
            {
                toast.success("Successfully updated current draft");
            }
            setModalState({...returnDefaultModalState()});
        }
        catch (err)
        {
            if (err?.response?.status === 422)
            {
                setFormError(err?.response?.data?.errors);
            }
            else
            {
                setModalState({...returnErrorModal(err, "Failed to Create Article", setModalState)})
            }

        }
    }

    const viewMetaModal = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.setModalState = setModalState;
        tempModalState.title = "Article Meta Tags"
        tempModalState.content = <CreateOrUpdateMetaTags article={props.article} setModalState={setModalState} metaSaved={saveMeta} />
        setModalState({...tempModalState});
    }

    const saveMeta = (title: string, keywords: string, description: string) => {
        setMetaTitle(title);
        setMetaKeywords(keywords);
        setMetaDescription(description);
    }

    useEffect(() => {
        if (props.article?.title !== undefined)
        {
            setTitle(props.article.title);
            setPostContent(props.article.content);
        }
        else
        {
            setTitle('');
            setPostContent('');
        }
    }, [props])

    return (
        <>
            {
                props.article?.published ? <Banner icon={FaExclamationTriangle} content={
                        <>
                            You are modifying <span className='font-bold'>a live article</span>
                        </>
                    }
                    type={BannerType.WARNING}
                    dismissible={false} /> : null
            }
            <div className='text-right my-2'>
                <SecondaryButton label={<><FaCog /> Manage Meta Tags</>} onClick={viewMetaModal} />
                <SecondaryButton label={<><FaTimes />Cancel Edit</>} onClick={props.cancelEdit} />
                <SmallButtonWithMenu defaultLabelClick={confirmSavingOverCurrentDraftVersion}
                     label='Update Current Draft' labelIdentifier='update-current-draft' items={saveAsDraftOptions}
                     menuEnabled={true} />
                <PrimaryButton label='Publish' onClick={() => submitArticle(true)} />
            </div>
            <Form errors={formError} loading={loading} handleSubmit={null}>
                <TextField type={TextFieldType.TEXT} label='Title' api_field_name='title' value={title}
                    placeholder='Article Title' onChange={setTitle} />

                <MdEditor style={{ height: '500px' }} renderHTML={text => mdParser.render(text)} onChange={handleEditorChange}
                    shortcuts={true} onImageUpload={handleImageUpload} defaultValue={postContent}/>


            </Form>
            <ModalDialog {...modalState} />
        </>
    )
}

CreateUpdateArticle.defaultProps = {
    article: null
}