import * as React from "react";
import {useEffect} from "react";
import {Loading, LoadingSize} from "devso-react-library";
import Wrapper from "../Wrapper";
import Sidebar from "../Sidebar";
import ContentWrapper from "../ContentWrapper";
import Footer from "../Footer";
import {HTTP_METHOD, sendRequest} from "../../Utils/APIManager";
import {useNavigate} from "react-router-dom";

export default function Logout() {

    const navigate = useNavigate();

    useEffect(() =>{
        (
            async () => {
                await sendRequest(null, '/auth/logout', HTTP_METHOD.POST);
                navigate("/admin");
            }
        )()
    }, [])

    return (
        <Wrapper>
            <Sidebar />
            <div className='w-full'>
                <ContentWrapper>
                    <Loading size={LoadingSize.SMALL} label='Logging out...please wait' />
                </ContentWrapper>
                <Footer />
            </div>

        </Wrapper>
    )
}