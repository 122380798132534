import * as React from "react";
import {useEffect, useState} from "react";
import {Section, SectionAPIResponse} from "../models/Sections";
import {sendRequest} from "../Utils/APIManager";

export default function useSection(refetchMode:boolean) {
    const [loading, setLoading] = useState(false);
    const [sections, setSections] = useState<Array<Section>>([])
    const [errors, setErrors] = useState(null);

    const fetchSections = async (refetchMode) => {
        try
        {
            if (!refetchMode)
            {
                setLoading(true);
            }
            const response : SectionAPIResponse = await sendRequest(null, '/sections');

            setSections(response.data);
        }
        catch (err)
        {
            setErrors(err);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        (
            async function() {
                await fetchSections(refetchMode);
            }
        )()
    }, [])

    return {loading, sections, errors, fetchSections, setSections}
}