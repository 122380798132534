import * as React from "react";
import {Article} from "../models/Article";
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter'
import DocumentMeta from "react-document-meta";

export default function ViewArticle(props: Article) {

    const meta = {
        title: props.meta_title,
        description: props.meta_description,
        meta: {
            charset: "utf-8",
            name: {
                keywords: props.meta_keywords,
                description: props.meta_description
            }
        }
    };

    return (
        <div className='pb-28'>
            <DocumentMeta {...meta} />
            <h1>{props.title}</h1>

            <ReactMarkdown children={props.content} remarkPlugins={[remarkGfm]} className='whitespace-pre-wrap'
                components={{
                    code({node, inline, className, children, ...props}) {
                        const match = /language-(\w+)/.exec(className || '')
                        return !inline && match ? (
                            <SyntaxHighlighter
                                children={String(children).replace(/\n$/, '')}
                                style={'coy'}
                                language={match[1]}
                                PreTag="div"
                                {...props}
                            />
                        ) : (
                            <code className={className} {...props}>
                                {children}
                            </code>
                        )
                    }
                }}/>
        </div>
    )
}