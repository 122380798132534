import * as React from "react";
import {useState} from "react";
import {
    Form,
    FormButtonContainer,
    ModalState,
    PrimaryButton,
    returnDefaultModalState,
    returnErrorModal,
    TextField,
    TextFieldType,
    SecondaryButton
} from "devso-react-library";
import {HTTP_METHOD, sendRequest} from "../../Utils/APIManager";
import {toast} from "react-toastify";

export type ChangePasswordProps = {
    setModalState: (modalState: ModalState) => void;
}

export default function ChangePassword(props: ChangePasswordProps) {

    const [formLoading, setFormLoading] = useState(false);
    const [formError, setFormError] = useState(null);

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = async () => {
        try
        {
            if (password === confirmPassword)
            {
                setFormLoading(true);
                setFormError(null);

                const data = {
                    password: password
                }

                await sendRequest(data, '/auth/change-password', HTTP_METHOD.PUT);
                toast.success("Password successfully changed");
                props.setModalState({...returnDefaultModalState()});
            }
            else
            {
                toast.error("Passwords do not match");
            }
        }
        catch (err)
        {
            props.setModalState({...returnErrorModal(err, "Failed to change password", props.setModalState)});
        }
        finally {
            setFormLoading(false);
        }
    }

    return (
        <Form loading={formLoading} errors={formError} handleSubmit={handleSubmit}>
            <TextField type={TextFieldType.PASSWORD} label='New Password' value={password} onChange={setPassword}
                       api_field_name='password' placeholder="Password" />
            <TextField type={TextFieldType.PASSWORD} label='Confirm Password' value={confirmPassword}
                       onChange={setConfirmPassword} api_field_name='confirm_password' placeholder='Confirm Password' />

            <FormButtonContainer loading={formLoading}>
                <PrimaryButton label='Change Password' />
                <SecondaryButton label='Cancel' onClick={() => {
                    props.setModalState({...returnDefaultModalState()})
                }} />
            </FormButtonContainer>
        </Form>
    )
}