import * as React from "react";
import {UserSidebarProps} from "../models/SidebarProps";
import useSection from "../hooks/useSections";
import {useEffect, useState} from "react";
import {Loading, LoadingSize, ModalDialog, ModalState} from "devso-react-library";
import {useMediaQuery} from "react-responsive";
import UserSectionList from "./UserSectionList";


export default function UserSidebar(props: UserSidebarProps) {
    const {loading, sections, errors, fetchSections, setSections} = useSection(false);
    const [modalState, setModalState] = useState<ModalState>(null);
    const [orderedSections, setOrderedSections] = useState([]);

    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 1224px)'
    })

    useEffect(() => {
        setOrderedSections(sections)
    }, [sections]);

    if (!loading && errors === null && sections !== null)
    {
        return (
            <>
                {/* Desktop sidebar */}
                <div className='w-[200px] overflow-y-auto z-100  hidden md:block bg-white md:h-auto h-full md:overflow-y-auto p-2 border-r-1 border-grey-300'>

                    <UserSectionList sections={orderedSections} slug={props.slug} isMobile={false} />

                    <ModalDialog {...modalState} />
                </div>

                {/* Mobile Sidebar */}
                <div className={(props.mobileSidebarOpen ? 'block' : 'hidden') + ' h-full z-100 ml-0 t-0 bg-white w-3/4 h-full p-2 border-r-1 border-grey-300 bg-grey-100'}>
                    <div className='z-100'>
                        <UserSectionList sections={orderedSections} slug={props.slug} isMobile={true} setMobileSidebarOpen={props.setMobileSidebarOpen} />

                        <ModalDialog {...modalState} />
                    </div>


                </div>
            </>

        )
    }
    else if (loading)
    {
        if (isTabletOrMobileDevice)
        {
            return null;
        }
        else
        {
            return (
                <Loading size={LoadingSize.LARGE} />
            )    
        }
        
    }
    else if (errors !== null)
    {
        return (errors.toString())
    }
    else
    {
        return null;
    }
}