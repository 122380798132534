import * as React from "react";
import {useEffect, useState} from "react";
//import DropdownMenu from "./DropdownMenu";
//import Cookies from 'js-cookie';
import {FaBars, FaCaretUp, FaKey, FaPlus, FaTimes} from "react-icons/fa";
import {useMediaQuery} from 'react-responsive'
import {useLocation, useNavigate} from "react-router-dom";
import useSection from "../../hooks/useSections";
import {
    DangerButton,
    Loading,
    LoadingSize,
    ModalDialog,
    ModalState,
    returnDefaultModalState,
    returnErrorComponent,
    returnErrorModal,
    SecondaryButton
} from "devso-react-library";
import ReactTooltip from "react-tooltip";
import AdminSectionList from "./AdminSectionList";
import CreateSectionForm from "./CreateSectionForm";
import {getCurrentSlug} from "../../Utils/SectionHelper";
import {Section} from "../../models/Sections";
import {HTTP_METHOD, sendRequest} from "../../Utils/APIManager";
import {toast} from "react-toastify";
import ChangePassword from "./ChangePassword";


export default function AdminSidebar() {

    const navigation = useNavigate();
    const location = useLocation();
    const {loading, sections, errors, fetchSections, setSections} = useSection(false);
    const [orderedSections, setOrderedSections] = useState([]);
    const [expanded, setExpanded] = useState(false);
    const [modalState, setModalState] = useState<ModalState>(null);

    const isTabletOrMobileDevice = useMediaQuery({
        query: '(max-device-width: 700px)'
    })

    const newSectionSubmitted = async () => {
        await fetchSections(true);
        const tempModalState = returnDefaultModalState();
        setModalState({...tempModalState})
    }

    const newSectionCancelled = () => {
        const tempModalState = returnDefaultModalState();
        setModalState({...tempModalState});
    }

    const showAddSectionModal = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.title = "Create New Section";
        tempModalState.content = <CreateSectionForm setModalState={setModalState} sections={sections}
                                                 sectionCreatedSuccessfully={newSectionSubmitted}
                                                 sectionCreationCancelled={newSectionCancelled}   />
        //tempModalState.primaryButtonLabel = null;
        //tempModalState.primaryButtonFunc = null;
        setModalState({...tempModalState});
    }

    const showDeleteSectionModal = (section: Section) => {

        console.log("Deleting section modal:", section);

        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.onClose = () => {
            setModalState({...returnDefaultModalState()})
        }
        tempModalState.title = "Are you sure";
        tempModalState.content = <p>
            <p>
                Are you sure you want to delete this section?
            </p>
            <p>
                All articles linked to the section will also be removed. This cannot be undone
            </p>
        </p>
        tempModalState.buttons = [
            <DangerButton label="Yes...I'm sure" onClick={() => deleteSection(section)} />,
            <SecondaryButton label='No' onClick={() => {
                setModalState({...returnDefaultModalState()})
            }} />
        ]
        setModalState({...tempModalState});
    }

    const deleteSection = async (section: Section) => {
        try
        {
            await sendRequest(null, `/sections/${section.id}`, HTTP_METHOD.DELETE);
            toast.success("Successfully deleted section");
            await fetchSections(true);
            setModalState({...returnDefaultModalState()});
        }
        catch (err)
        {
            setModalState({...returnErrorModal(err, "Failed to delete section", setModalState)})
        }
    }

    const sectionsReordered = async (newOrderedSections, updated_section, originalOrder, updatingParent: boolean = false) => {
        try
        {
            //The ordered sections we get back are an array of parents where sub_items includes
            //the child sections for the parent. We need to reflatten this so its one single array for each parent
            //that we can send to the database

            let i = 0;
            for (const section of updated_section) {
                let array = [];
                if (!updatingParent)
                {

                    array.push(section);

                    section.sub_items.forEach((sub_item, index) => {
                        sub_item.order_index = index;
                        array.push(sub_item);
                    })

                    //array = array.concat(section.sub_items);
                }
                else
                {
                    section.order_index = i;
                    array.push(section);
                }



                console.log("Posting array", array);
                const data = {
                    sections: JSON.stringify(array)
                }

                await sendRequest(data, "/sections", HTTP_METHOD.PUT);
                i++;
            }

            setOrderedSections(newOrderedSections);
            setSections(newOrderedSections);
            //await fetchSections(true);
            toast.success("Reordering of sections completed successfully");
        }
        catch(err)
        {
            const tempModalState = returnErrorModal(err, "Error Ordering Sections", setModalState);
            setModalState({...tempModalState});
            setOrderedSections(originalOrder)
        }
    }

    useEffect(() => {
        if (isTabletOrMobileDevice)
        {
            const element = document.getElementById("nav_container");
            if (element !== null)
            {
                element.style.display = "none";
            }

        }

    }, [])

    useEffect(() => {
        window.setTimeout(function() {
            if (!expanded && isTabletOrMobileDevice)
            {
                const element = document.getElementById("nav_container");
                element.style.display = "none";
            }
        }, 300)
        if (expanded && isTabletOrMobileDevice)
        {
            const element = document.getElementById("nav_container");
            element.style.display = "block";
        }

    }, [expanded])

    const showPasswordModal = () => {
        const tempModalState = returnDefaultModalState();
        tempModalState.open = true;
        tempModalState.setModalState = setModalState;
        tempModalState.title = "Change Password";
        tempModalState.content = <ChangePassword setModalState={setModalState} />
        setModalState({...tempModalState});
    }

    //const email = decodeURIComponent(Cookies.get("Email")).replace("+", " ");
    //const email_display = email.length > 17 ? email.substring(0, 17) + "..." : email
    //console.log("Cookie USER ID is:", Cookies.get("Name"));
    return (
        <div className='!z-0 absolute md:relative z-40 md:h-screen w-full md:w-[240px] bg-white border-r-[1px] border-r-gray-300 p-4 md:flex-none flex flex-row'>
            <div className='w-full h-[calc(100%-200px)] overflow-y-auto '>
                <div className='text-right'>
                    <button data-tip='Add Section' onClick={showAddSectionModal}><FaPlus /></button>
                </div>

                {
                    (
                        () => {
                            if (loading)
                            {
                                return <Loading size={LoadingSize.SMALL} />
                            }
                            else if (!loading && errors !== null)
                            {
                                return returnErrorComponent(errors, "Failed to get sections");
                            }
                            else if (!loading && sections !== null)
                            {
                                console.log("Current Slug:", getCurrentSlug(location.pathname))
                                return (
                                    <div className='h-[calc(100%-400px]'>
                                    <AdminSectionList sections={sections} slug={getCurrentSlug(location.pathname)}
                                          sectionOrderUpdated={sectionsReordered}
                                          showDeleteSectionModal={showDeleteSectionModal}
                                          setModalState={setModalState} refetchSections={null} />
                                    </div>
                                )
                            }
                            else
                            {
                                return null;
                            }
                        }
                    )()
                }
                <div className='absolute bottom-0 mb-28 border-t-[1px] border-t-gray-400 w-[210px] py-3'>
                    <div className='flex flex-row hover:underline cursor-pointer py-2' onClick={showPasswordModal} >
                        <FaKey className='inline mr-2' /> Change Password
                    </div>
                    <div className='flex flex-row hover:underline cursor-pointer py-2' onClick={() => navigation('/admin/logout')}>
                        <FaTimes className='inline mr-2' /> Logout
                    </div>
                </div>
            </div>
            <div className='w-1/2 md:hidden block text-white text-right cursor-pointer'
                onClick={() => setExpanded(!expanded)}>
                {
                    !expanded ? <FaBars className='text-primary text-right float-right mt-3 mr-5' />
                        : <FaCaretUp className='text-primary text-right float-right mt-3 mr-5' />
                }

            </div>
            <ModalDialog {...modalState} />
            <ReactTooltip />
        </div>
    )
}