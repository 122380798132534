import * as React from "react";
import TopHeader from "./TopHeader";

export default function Wrapper(props) {
    return (
        <div className='bg-gray-100 overflow-hidden h-full'>
            <TopHeader />
            <div className='flex flex-col md:flex-row bg-gray-100 overflow-x-hidden overflow-hidden] '>
                {
                    props.children
                }
            </div>
        </div>
    )
}